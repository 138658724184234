import React from 'react'
import { connect } from 'react-redux'
import {
    flightsSelector,
    prevCondSelector,
    searchFlightsIsFinishedSelector
} from '../flight/baseSelector'
import { MID_RECOMMEND_POSITION } from '../../../../constants/list/enums/recommend.js'
import FlightTrainCombinationContainer from './flightTrainCombinationContainer'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector'
import { showRecommendOrderSelector } from './midRecommendSelector'
import { isShowRecommendSelector, recommendVersionSelector } from './recommendSelector'

class MidRecommendContainer extends React.Component {
    constructor(props) {
        super(props);
        this.midRecommendType = this.midRecommendType.bind(this)
    }

    midRecommendType(recommendType, pos) {
        let { onBook, actions, isBuildUpMode, onPopupFlightDetail, splitRound,
            selectFlightItineryId, fixSortBar, selectedFlightFixStatus, ubtOriginFlight } = this.props;

        if (recommendType === 'FLIGHT_TRAIN') {
            return <FlightTrainCombinationContainer ubtOriginFlight={ubtOriginFlight} selectedFlightFixStatus={selectedFlightFixStatus}
                selectFlightItineryId={selectFlightItineryId} fixSortBar={fixSortBar} splitRound={splitRound}
                onPopupFlightDetail={onPopupFlightDetail} onBook={onBook} actions={actions} isBuildUpMode={isBuildUpMode} order={pos} />
        }
    }

    render() {
        let { searchFlightsIsFinish, count, showRecommendOrder, flights, recommendVersion } = this.props;
        MID_RECOMMEND_POSITION.getVersion(recommendVersion).enums.forEach((item) => {
            item.value.count === count ? count = item : undefined
        })

        let versionList = ['ONE', 'TWO', 'THREE'],
            getMidRecSizeOver = theCount => count === MID_RECOMMEND_POSITION.getVersion(recommendVersion)[versionList[theCount]] && showRecommendOrder.size > theCount
                ? this.midRecommendType(showRecommendOrder.get(theCount), theCount) : null,
            recommend0 = getMidRecSizeOver(0),
            recommend1 = getMidRecSizeOver(1),
            recommend2 = getMidRecSizeOver(2)

        return (
            searchFlightsIsFinish && flights.get('list').size !== 0 && (recommend0 || recommend1 || recommend2) ? (
                <div>
                    {recommend0}
                    {recommend1}
                    {recommend2}
                </div>
            ) : null
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        searchFlightsIsFinish: searchFlightsIsFinishedSelector(state),
        prevCond: prevCondSelector(state),
        showRecommendOrder: showRecommendOrderSelector(state, props),
        searchNoResultRecommend: searchNoResultRecommendSelector(state),
        recommendVersion: recommendVersionSelector(state),
        isShowRecommend: isShowRecommendSelector(state),
        flights: flightsSelector(state),
    }
};

export default connect(mapStateToProps)(MidRecommendContainer)
