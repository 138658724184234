import React from "react"
import PopDialog from '../../common/PopDialog'
import FLIGHT_TRAIN_MESSAGE from '../../../../constants/list/enums/flightTrainMessage'
/**
 * 空铁组合弹窗
 *
 */

const innerUED = {
    popupMargin: {
        margin: '30px 10px',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: '1.5em'
    }
};

const FlightTrainPopup = ({ ...props }) => {
    const { isShow, onClick } = props
    return (
        !isShow ? null : (
            <PopDialog contents={
                <div className="basic-alert alert-train-travel" style={innerUED.popupMargin}>
                    <i className="ico-warning-max" />
                    <div className="alert-title">{FLIGHT_TRAIN_MESSAGE.ALERT_TITLE}</div>
                    <div className="alert-body">
                        {FLIGHT_TRAIN_MESSAGE.ALERT_CONTENT.map((item, index) => <div className="alert-li" key={index}>{index + 1}.{item}</div>)}
                        <div className="train-tips">
                            <strong>{FLIGHT_TRAIN_MESSAGE.TRAIN_TIP_TITLE}</strong>
                            <p>{FLIGHT_TRAIN_MESSAGE.TRAIN_TIP_CONTENT}</p>
                        </div>
                    </div>
                    <div className="alert-footer">
                        <div className="btn-group">
                            <a href="javascript:;" className="btn" onClick={onClick}>我知道了</a>
                        </div>
                    </div>
                </div>}
            />
        )
    )
}

export default FlightTrainPopup
