import React from "react"
/**
 * 两舱无直飞补充直飞与中转
 * 两舱折叠组件
 */
const ShowMore = ({ ...props }) => {
    const { title, iconClassName, onClick } = props
    return (
        <div className='more-result'>
        <a href="javascript:;" className="trigger" onClick={onClick}>
            {`${title || '查看更多直飞航班'}`}
            <i className={iconClassName || "arrow-down-bold"}></i>
        </a>
    </div>
    )
}

export default ShowMore
