import { createSelector } from 'reselect'
import Immutable from 'immutable'
import { adjacentAirlineFormatSelector } from './adjacentAirlineSelector'
import { adjacentDateSelector } from './adjacentDateSelector'
import { flightTrainLowestMiseryIndex, flightTrainCombinationSelector } from './flightTrainCombinationSelector'
import { searchFlightsIsFinishedSelector, prevCondSelector } from '../flight/baseSelector'
import { isShowRecommendSelector } from './recommendSelector'

const recommendOrderSelector = createSelector([adjacentAirlineFormatSelector, adjacentDateSelector, flightTrainLowestMiseryIndex, flightTrainCombinationSelector,isShowRecommendSelector,prevCondSelector],
    (adjacentAirlineFormat, adjacentDate, flightTrainLowestMiseryIndex, flightTrainCombination,isShowRecommend, _prevCond) => {
        let showOrder = []

        if (flightTrainCombination.size > 0 && adjacentAirlineFormat.size > 0) {
            if (flightTrainLowestMiseryIndex.get('res') > adjacentAirlineFormat.get(0).get('miseryIndex') && isShowRecommend) {
                showOrder.push('ADJACENT_AIRLINE')
                showOrder.push('FLIGHT_TRAIN')
            } else {
                showOrder.push('FLIGHT_TRAIN')
                showOrder.push('ADJACENT_AIRLINE')
            }
        }
        else if (flightTrainCombination.size > 0) {
            showOrder.push('FLIGHT_TRAIN')
        }
        else if (adjacentAirlineFormat.size > 0) {
            showOrder.push('ADJACENT_AIRLINE')
        }
        if (adjacentDate.size > 0) {
            showOrder.push('ADJACENT_DATE')
        }
        return Immutable.fromJS(showOrder)
    })

export const showRecommendOrderSelector = createSelector([searchFlightsIsFinishedSelector, recommendOrderSelector], (isFinish, recommendOrder) => {
    if (isFinish) {
        return recommendOrder
    }
    return Immutable.fromJS([])
})
