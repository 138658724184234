import React from "react"
import { connect } from "react-redux"
import { List } from "immutable"
// import GroupTitle from "../../../../components/list/result/recommend/groupTitle"
import moment from 'moment'
import { flightTrainCombinationSelector } from "./flightTrainCombinationSelector"
import FlightContainer from "../flight/flightContainer"
import ShowMore from '../../../../components/list/result/recommend/showMore'
import FlightTrainPopup from '../../../../components/list/result/recommend/flightTrainPopup'
import { currentSegmentSeqSelector, prevCondSelector, isBuildUpSelector , containsTaxSelector } from '../flight/baseSelector'
import { UBT_CENTRAL_INFO } from '../../../../constants/list/enums/recommend'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'
import converter, { SessionHandler } from "../../../../sources/list/converter";
import { calcSplitRoundSelector } from '../../../../sources/list/calcSplitRoundStateSelector';
//helper fn
export const handleFold = function (state, arr, gate) {
    let data = List([])
    if (!arr)
        return data;
    if (state.isFold == 'show') {
        data = arr.map((item, index) => {
            if (index > gate - 1) {
                return item.set('_isFoldHide', true)
            }
            return item
        })
    } else {
        data = arr.map((item, index) => {
            if (index > gate - 1) {
                return item.set('_isFoldHide', false)
            }
            return item
        })
    }
    return data
}

class FlightTrainCombinationContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFold: 'show',
            showPopNoticeBeforeBuy: false,
            ubtSequence: [],
            ubtPosition: UBT_CENTRAL_INFO[props.order].value
        }
        this.changeAppendShowMoreVisibility = this.changeAppendShowMoreVisibility.bind(this)
        this.popNoticeBeforeBuy = this.popNoticeBeforeBuy.bind(this)
        this.togglePopupVisibility = this.togglePopupVisibility.bind(this)
    }

    componentDidMount() {
        let { flightTrains, prevCond, order } = this.props
        if (flightTrains.size > 0 && UBT_CENTRAL_INFO[order].value) {
            // 说明有空铁组合，埋点
            let sequence = []
            prevCond.get('flightSegments').forEach(flight => {
                sequence.push({
                    From: flight.get('departureCityCode'),
                    To: flight.get('arrivalCityCode'),
                    StartTime: moment(flight.get("departureDate")).format("YYYY-MM-DD HH:mm:ss")
                })
            })
            this.setState({ ubtSequence: sequence })
        }
    }

    changeAppendShowMoreVisibility(visibility) {
        return () => this.setState({ isFold: visibility })
    }

    popNoticeBeforeBuy() {
        this.setState({ showPopNoticeBeforeBuy: true })
    }

    togglePopupVisibility() {
        this.setState({ showPopNoticeBeforeBuy: !this.state.showPopNoticeBeforeBuy })
    }


    render() {
        const { flightTrains, isBuildUpMode, actions, currentSegmentSeq, onBook, prevCond, onPopupFlightDetail, containsTax, selectFlightItineryId, fixSortBar, selectedFlightFixStatus, ubtOriginFlight } = this.props
        let ubtRecommendRawData = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct,
            otherParams = {}
        if (ubtRecommendRawData && Object.keys(ubtRecommendRawData).length > 0) {
            let recommendOfPosition = ubtRecommendRawData[this.state.ubtPosition],
                i = 0,
                num = []
            if (recommendOfPosition) {
                for (; i < recommendOfPosition.length; i++) {
                    let currentRecommend = recommendOfPosition[i]
                    if (currentRecommend.Type == 3) {
                        if (!currentRecommend.trainnum) {
                            currentRecommend.trainnum = []
                        }
                        Array(currentSegmentSeq).fill(0).forEach((val, index) => {
                            if (!currentRecommend.trainnum[index]) {
                                currentRecommend.trainnum[index] = 0
                            }
                        })
                        num = currentRecommend.trainnum
                        break
                    }
                }
                if (i == recommendOfPosition.length) {
                    num = Array(currentSegmentSeq).fill(0)
                }
                num[currentSegmentSeq] = flightTrains.size
                otherParams.trainnum = num
            }
        }
        let data = handleFold(this.state, flightTrains, 1),
            flightTrainTitle = "火车推荐",
            stateNow = this.state,
            handleBook = function () {
                return function (...args) {
                    let ubtSourceInfoOfThis = {
                        Source: 1,
                        Type: 3,
                        Position: stateNow.ubtPosition,
                        RecommendOrder: 1,
                        Sequence: stateNow.ubtSequence,
                        OriginFlight:ubtOriginFlight
                    },
                        ubtProps = {},
                        ubtSourceInfoOfListPage = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct ? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct.SourceInfo : null
                    ubtSourceInfoOfThis = Object.assign(ubtSourceInfoOfThis, stateNow.ubtOtherParams)
                    ubtProps.SourceInfo = converter.ubtSourceInfoCombiner(ubtSourceInfoOfListPage, ubtSourceInfoOfThis)
                    converter.saveRecommendSourceInfoForBookingPage(ubtProps, prevCond.get("transactionID"))
                    SessionHandler.setAdjacentRecommendType(ubtSourceInfoOfThis)
                    onBook(...args)
                }
            }
        let content = data.map((flight, i) => {
            if (!flight)
                return null
            let segments = flight.get("flightSegments")
            let isSelected = flight.get('itineraryId') == selectFlightItineryId
            segments && segments.size && segments.some(seg => {
                if (!seg.get('isAllTrains')) {
                    flightTrainTitle = "空铁组合"
                }
            })
            return isBuildUpMode &&
                segments.size < 2 ? null : (
                    <FlightContainer
                        onBook={handleBook()}
                        key={i}
                        flight={flight}
                        actions={actions}
                        index={i}
                        currentSegmentSeq={currentSegmentSeq}
                        onPopupFlightDetail={onPopupFlightDetail}
                        splitRound={this.props.splitRound}
                        sortContainsTax={containsTax}
                        isSelected={isSelected}
                        selectedFlightFixStatus={isSelected ? selectedFlightFixStatus : null}
                        fixSortBar={isSelected ? fixSortBar : null}
                    />
                )
        })
        return data && data.size > 0 ? (
            <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_LOAD, 3, 1, this.state.ubtPosition,
                this.state.ubtSequence, otherParams)}>
                <div className='flight-list'>
                    <div className="flight-train">
                        <div className="otherline-title">
                            <i className="ico-flt-train" />
                            <div className="title">
                                {flightTrainTitle}
                                <span className="abbr" onClick={this.popNoticeBeforeBuy}>购买需知</span>
                                <span className="hint">{"曲线出行，好玩便宜"}</span>
                            </div>
                        </div>
                        {content}
                        {
                            this.state.isFold === 'show' && data.size >= 2 ? <ShowMore title={`查看更多${flightTrainTitle}`} onClick={this.changeAppendShowMoreVisibility('hide')} /> : data.size >= 2 ? <ShowMore title={`收起 `} iconClassName="arrow-up-bold" onClick={this.changeAppendShowMoreVisibility('show')} /> : null
                        }
                        <FlightTrainPopup isShow={this.state.showPopNoticeBeforeBuy} onClick={this.togglePopupVisibility} />
                    </div>
                </div>
            </UbtBoundary>
        ) : null
    }
}

const mapStateToProps = (state, props) => {
    const { splitRound } = props,
        flightTrains = calcSplitRoundSelector(splitRound, flightTrainCombinationSelector, state, true),
        currentSegmentSeq = calcSplitRoundSelector(splitRound, currentSegmentSeqSelector, state, true)
    return {
        flightTrains,
        currentSegmentSeq,
        prevCond: prevCondSelector(state),
        isBuildUpMode: isBuildUpSelector(state),
        containsTax: containsTaxSelector(state),
    }
}

const mapDispatchToProps = _dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(
    FlightTrainCombinationContainer
)
